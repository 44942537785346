<template>
  <b-modal
    ref="consumoCredito"
    size="sm"
    centered
    hide-footer
    hide-header
    @hidden="fechar"
  >
    <div>
      <div class="text-center">
        <font-awesome-icon
          icon="fa-circle-exclamation"
          class="text-warning mt-2"
          size="8x"
        />
        <div class="mt-2">
          <h3>Atenção!</h3>
          <h3>{{ getSubTitulo() }}</h3>
          <div class="my-2">
            {{ getTexto() }}
          </div>
        </div>
        <div
          class="d-flex justify-content-center"
          style="gap:1rem"
        >
          <button
            class="p-60 btn btn-outline-secondary mb-50"
            @click="fechar"
          >
            {{ creditoConsumo != undefined && creditoConsumo.quantidadeEmpresaNaoAtualizavel == 0 ? 'Não' : 'Cancelar'}}
          </button>
          <button
            class="p-60 btn btn-outline-primary mb-50"
            @click="$emit('atualizar')"
          >
            Sim
          </button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>

export default {
  props: {
    tipoRotina: {
      type: String,
      required: true,
    },
    creditoConsumo: {
      type: Object,
      default: undefined,
    },
  },
  methods: {
    fechar() {
      this.$emit('fechar')
    },
    getSubTitulo() {
      this.subTitulo = ''
      if (this.validarCreditoConsumo(this.creditoConsumo)) {
        return `Atualização de ${this.tipoRotina}`
      }
      return 'Saldo insuficiente para atualização'
    },
    getTexto() {
      if (this.creditoConsumo !== undefined && this.creditoConsumo.quantidadeEmpresaNaoAtualizavel === 0) {
        return `Ao confirmar a atualização serão consumidos ${this.creditoConsumo.quantidadeCreditoParaExecucao} créditos de seu saldo em conta. Deseja concluir a atualização?`
      }
      if (this.validarCreditoConsumo(this.creditoConsumo)) {
        return `Ao confirmar a atualização será consumido ${this.tipoRotina === 'DCTFWeb' ? 'dois' : 'um'} crédito${this.tipoRotina === 'DCTFWeb' ? 's' : ''} de seu saldo em conta. Deseja concluir a atualização?`
      }
      return `Você possui ${this.creditoConsumo.creditoDisponivel} créditos disponíveis. Isso permitirá a atualização de ${this.creditoConsumo.quantidadeEmpresaAtualizavel} empresa(s). Deseja continuar ou aplicar uma nova seleção?`
    },
    validarCreditoConsumo(creditoConsumo) {
      return creditoConsumo === undefined || (creditoConsumo !== undefined && creditoConsumo.quantidadeEmpresaNaoAtualizavel === 0)
    },
  },
}
</script>
